import React, { useEffect, useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Table, Modal, Form, Input, Select, Row, Col, Switch, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import isBewteen from 'dayjs/plugin/isBetween';
import { createQuestion, getTask, updateQuestion, removeQuestion } from '../../api';
import { QUESTION_TYPES } from '../../consts';
import OSSUploader from '../../components/img-uploader';

dayjs.extend(isBewteen);

const InputGroup = Input.Group;
const { Option } = Select;

function QuestionAnswer(props) {
  const [isImage, setIsImage] = useState(false);
  useEffect(() => {
    setIsImage(!!props.image);
  }, []);
  const onValueChange = (e) => {
    props.onChange({
      ...props,
      value: e.target.value,
    });
  };
  const handleCheckCorrect = (checked) => {
    props.onChange({
      ...props,
      isCorrect: checked,
    });
  };

  const onIndexChange = (e) => {
    props.onChange({
      ...props,
      index: e.target.value,
    });
  };

  const handleCheckImage = (checked) => {
    setIsImage(checked);
    if (checked) {
      props.onChange({
        ...props,
        value: '',
      });
    }
    if (!checked) {
      props.onChange({
        ...props,
        image: '',
      });
    }
  };

  const hanldeOptionImageUpload = (file) => {
    props.onChange({
      ...props,
      image: file,
    });
  };

  switch (props.type) {
    case 'SINGLE_CHOICE':
    case 'MULTI_CHOICE':
      return (
        <>
          <Row>
            <Col span={17}>
              <InputGroup>
                <Row>
                  <Col span={6}>
                    <Input placeholder="唯一选项，如「A」" defaultValue={props.index} onChange={onIndexChange} />
                  </Col>
                  <Col span={18}>
                    {isImage && <OSSUploader onChange={hanldeOptionImageUpload} value={props.image} />}
                    {!isImage && <Input defaultValue={props.value} onChange={onValueChange} />}
                  </Col>
                </Row>
              </InputGroup>
            </Col>
            <Col span={1} />
            <Col span={3}>
              <Switch checkedChildren="正确" unCheckedChildren="错误" checked={props.isCorrect} onChange={handleCheckCorrect} />
            </Col>
            <Col span={3}>
              <Switch checkedChildren="图片" unCheckedChildren="文字" checked={isImage} onChange={handleCheckImage} />
            </Col>
          </Row>
        </>
      );
    case 'FILL_BLANK':
      return <Input defaultValue={props.value} onChange={onValueChange} placeholder="请按上方占位符顺序填写正确答案" />;
    default:
      return <></>;
  }
}

export default function({ history }) {
  const { taskId } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [taskModel, setTaskModel] = useReducer((state, val) => val, {});
  const ranges = useSelector((state) => state.ranges);
  const load = async () => {
    let data = await getTask(window.parseInt(taskId));
    data.questions = data.questions.map((question) => {
      let options = [];
      if (question.type === 'FILL_BLANK') {
        options = question.answer.map((item) => ({
          value: item.content,
          index: item.index,
          isCorrect: true,
        }));
      } else {
        options = question.options.map((option) => ({
          ...option,
          isCorrect: !!question.answer.find((answer) => answer === option.index),
          value: option.content,
          image: option.contentType === 'IMAGE' ? option.url : '',
        }));
      }
      question.description = question.description.map((desc) => desc.url);
      return {
        ...question,
        options,
      };
    });
    setDataSource(data.questions);
  };
  useEffect(() => {
    load();
  }, []);
  const handleCreate = () => {
    setTaskModel({
      title: '',
      type: 'SINGLE_CHOICE',
      options: [],
      answer: [],
    });
    form.setFieldsValue({
      title: '',
      type: 'SINGLE_CHOICE',
      options: [],
      answer: [],
      description: [],
    });
    setModalVisible(true);
  };
  const handleEdit = (row) => {
    setTaskModel(row);
    form.setFieldsValue(row);
    setModalVisible(true);
  };
  const handleRemove = async ({ id }) => {
    await removeQuestion({ quizId: Number(taskId), questionId: id });
    load();
  };
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '标题',
      dataIndex: 'title',
    },
    {
      title: '外部 ID',
      dataIndex: 'externalId',
    },
    {
      title: '类型',
      render(_, data) {
        switch (data.type) {
          case 'SINGLE_CHOICE':
            return '单选';
          case 'MULTI_CHOICE':
            return '多选';
          case 'FILL_BLANK':
            return '填空';
          default:
            return '';
        }
      },
    },
    {
      title: '更新日期',
      render(_, data) {
        return dayjs(data.updatedAt).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '操作',
      render: (_, row) => {
        return (
          <>
            <Button type="link" onClick={() => handleEdit(row)}>
              编辑
            </Button>
            <Popconfirm title="确认删除？" onConfirm={() => handleRemove(row)} okText="是" cancelText="否">
              <Button danger type="link">
                删除
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const handleTableChange = (params) => {
    setPagination(params);
  };
  const handleSubmitTask = () => {
    return form.validateFields().then(async (values) => {
      const answer = buildAnswerData(taskModel.options);
      const options = buildOptionsData(taskModel.options);
      const data = {
        id: taskModel.id,
        title: values.title,
        quizId: Number(taskId),
        externalId: Number(values.externalId),
        type: values.type,
        options,
        answer,
        ignoreAnswerOrder: values.type === 'MULTI_CHOICE',
        description: values.description.map((description) => ({
          type: /.mp4/.test(description) ? 'VIDEO' : 'IMAGE',
          url: description,
        })),
      };
      if (!data.id) {
        await createQuestion(data);
      } else {
        delete data.quizId;
        await updateQuestion(data);
      }
      load();
    });
  };
  const handleModalOk = async () => {
    await handleSubmitTask();
    setModalVisible(false);
  };
  const handleCancelModal = () => {
    setModalVisible(false);
  };
  const handleRemoveOption = (index) => {
    const { options } = taskModel;
    setTaskModel({
      ...taskModel,
      options: options.filter((option, idx) => idx !== index),
    });
  };
  const handleAddOption = () => {
    const { options, type } = taskModel;
    setTaskModel({
      ...taskModel,
      options: [
        ...options,
        {
          type,
          value: null,
          answer: '',
          id: options.length,
        },
      ],
    });
  };
  const handleQuestionTypeChange = (type) => {
    setTaskModel({
      ...taskModel,
      type,
      options: [],
    });
  };
  const buildOptionsData = (options) =>
    options.map((option) => ({
      index: option.index,
      content: taskModel.type !== 'FILL_BLANK' ? option.value : '',
      contentType: option.image ? 'IMAGE' : 'TEXT',
      url: option.image,
    }));

  const buildAnswerData = (options) => {
    const answers = [];
    options.forEach((option) => {
      switch (taskModel.type) {
        case 'SINGLE_CHOICE':
        case 'MULTI_CHOICE':
          if (option.isCorrect) {
            answers.push(option.index);
          }
          break;
        case 'FILL_BLANK':
        default:
          answers.push(option.value);
      }
    });
    return answers;
  };
  const handleOptionChange = (option) => {
    const { options } = taskModel;
    switch (option.type) {
      case 'SINGLE_CHOICE':
        if (option.isCorrect) {
          setTaskModel({
            ...taskModel,
            options: options.map((opt, index) => (option.id === index ? option : { ...opt, isCorrect: false })),
          });
        } else {
          setTaskModel({
            ...taskModel,
            options: options.map((opt, index) => (option.id === index ? option : opt)),
          });
        }
        break;
      case 'MULTI_CHOICE':
      case 'FILL_BLANK':
      default:
        setTaskModel({
          ...taskModel,
          options: options.map((opt, id) => (option.id === id ? option : opt)),
        });
    }
  };
  return (
    <>
      <div>
        <Button onClick={handleCreate} type="primary" style={{ marginBottom: 16 }}>
          新建
        </Button>
        <Table dataSource={dataSource} columns={columns} rowKey="externalId" pagination={{ ...pagination, total }} onChange={handleTableChange} />
      </div>
      <Modal
        title="题目编辑"
        visible={modalVisible}
        width="80%"
        destroyOnClose={true}
        onCancel={handleCancelModal}
        onOk={handleModalOk}
        okText="提交"
        cancelText="取消"
      >
        <Form initialValues={taskModel} form={form} layout="vertical">
          <Tooltip placement="bottomRight" title="填空题请使用{{文字字数}}来预留空白位，如2个字的答案就是{{2}}" visible={taskModel.type === 'FILL_BLANK'}>
            <Form.Item
              name="title"
              label="标题"
              rules={[
                {
                  required: true,
                  message: '请输入题目标题',
                },
              ]}
            >
              <Input placeholder="请输入题目名称" />
            </Form.Item>
          </Tooltip>

          <Form.Item name="description" label="题目图片">
            <OSSUploader isMulti />
          </Form.Item>

          <Form.Item name="externalId" label="外部 ID">
            <Input placeholder="请输入外部 ID" />
          </Form.Item>

          <Form.Item name="type" label="题目类型">
            <Select style={{ width: 120 }} onChange={handleQuestionTypeChange}>
              {QUESTION_TYPES.map((type) => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {taskModel.type && (
            <Form.Item label="问题选项">
              {taskModel.options.map((option, index) => (
                <Row>
                  <Col span={21}>
                    <QuestionAnswer
                      image={option.url}
                      key={option.index || index}
                      id={index}
                      index={option.index}
                      value={option.content || option.value}
                      isCorrect={option.isCorrect}
                      type={taskModel.type}
                      onChange={handleOptionChange}
                    />
                  </Col>
                  <Col span={3}>
                    <Button type="danger" onClick={() => handleRemoveOption(index)}>
                      <DeleteOutlined />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button type="dashed" onClick={handleAddOption}>
                <PlusOutlined /> 添加选项
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}
