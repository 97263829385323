import React from 'react';
import { Table, Button, Modal, Popconfirm } from 'antd';
import { getUserMoments, removeUserMoment, removeUserComment } from '../../api';

export default class UserMoments extends React.Component {
  state = {
    commentModalVisible: false,
    commentData: [],
    previewModalVisible: false,
    previewMedia: [],
    userMoments: [],
    pagination: {
      pageSize: 10,
      current: 1,
    },
    total: 0,
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: '姓名',
        dataIndex: ['user', 'name'],
      },
      {
        title: '头像',
        render(_, data) {
          return <img style={{ width: '50px' }} src={data.user.avatar} alt="avatar" />;
        },
      },
      {
        title: '职位',
        dataIndex: ['user', 'position'],
      },
      {
        title: '店铺',
        dataIndex: ['user', 'chanelShopName'],
      },
      {
        title: '内容',
        dataIndex: 'content',
        width: '20%',
      },
      {
        title: '图片/视频',
        render: (_, data) => {
          return (
            <Button type="link" onClick={() => this.handlePreviewMedia(data)}>
              查看
            </Button>
          );
        },
      },
      {
        title: '年份',
        dataIndex: 'year',
      },
      {
        title: '点赞数量',
        render: (_, data) => {
          return data.likes.length;
        },
      },
      {
        title: '评论数量',
        dataIndex: 'instantCommentsCount',
      },
      {
        title: '操作',
        render: (_, data) => {
          return (
            <>
              <Button type="link" onClick={() => this.handleManageComment(data)}>
                管理评论
              </Button>
              <Popconfirm title="确认删除？" onConfirm={() => this.handleRemoveMoment(data.id)} okText="是" cancelText="否">
                <Button danger type="link">
                  删除
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ],
    commentColumns: [
      {
        title: '姓名',
        dataIndex: ['user', 'name'],
      },
      {
        title: '头像',
        render(_, data) {
          return <img src={data.user.avatar} style={{ width: '50px' }} alt="avatar" />;
        },
      },
      {
        title: '内容',
        dataIndex: 'content',
      },
      {
        title: '操作',
        render: (_, data) => {
          return (
            <Popconfirm title="确认删除？" onConfirm={() => this.handleRemoveComment(data)} okText="是" cancelText="否">
              <Button danger type="link">
                删除
              </Button>
            </Popconfirm>
          );
        },
      },
    ],
  };

  handleRemoveMoment = async (id) => {
    await removeUserMoment(id);
    this.load();
  };

  handleManageComment = (data) => {
    this.setState({
      commentModalVisible: true,
      commentData: data.comments,
    });
  };

  handleRemoveComment = async (comment) => {
    await removeUserComment(comment.id);
    await this.load();
    const moment = this.state.userMoments.find((moment) => moment.id === comment.userMomentId);
    this.setState({
      commentData: moment.comments,
    });
  };

  handlePreviewMedia = (data) => {
    this.setState({
      previewMedia: data.media,
      previewModalVisible: true,
    });
  };

  load = () => {
    return new Promise(async (resolve) => {
      const { data, total } = await getUserMoments({
        take: this.state.pagination.pageSize,
        skip: (this.state.pagination.current - 1) * this.state.pagination.pageSize,
      });
      this.setState(
        {
          userMoments: data,
          total,
        },
        resolve
      );
    });
  };

  handleTableChange = (params) => {
    this.setState(
      {
        pagination: params,
      },
      this.load
    );
  };

  handleClosePreviewModal = () => {
    this.setState({
      previewModalVisible: false,
    });
  };

  handleCloseCommentModal = () => {
    this.setState({
      commentModalVisible: false,
    });
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const { userMoments, pagination, columns, total, previewModalVisible, previewMedia, commentModalVisible, commentData, commentColumns } = this.state;
    return (
      <div>
        <Table dataSource={userMoments} columns={columns} rowKey="id" pagination={{ ...pagination, total }} onChange={this.handleTableChange} />
        <Modal visible={previewModalVisible} footer={null} onCancel={this.handleClosePreviewModal}>
          <div className="flex flex-wrap justify-around">
            {previewMedia.map((media) => {
              return media.type === 'IMAGE' ? <img src={media.url} className="w-30" alt="moment" /> : <video controls src={media.url} className="w-30" />;
            })}
          </div>
        </Modal>
        <Modal visible={commentModalVisible} width="70%" footer={null} onCancel={this.handleCloseCommentModal}>
          <Table dataSource={commentData} columns={commentColumns} rowKey="id" />
        </Modal>
      </div>
    );
  }
}
