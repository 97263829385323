const initState = {
  ranges: {},
};

export default function(state = initState, action) {
  switch (action.type) {
    case 'setRangeDetail':
      return {
        ...state,
        ranges: {
          ...state.ranges,
          [action.payload.id]: action.payload,
        },
      };
    case 'setNavBarCollapsed':
      return {
        ...state,
        navBarCollapsed: action.payload,
      };
    default:
      return state;
  }
}
