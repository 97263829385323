import React from 'react';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { UnorderedListOutlined, FieldTimeOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory, withRouter } from 'react-router-dom';
import PropType from 'prop-types';
import styles from './navbar.module.css';

const { SubMenu } = Menu;
const { Sider } = Layout;
export const menus = [
  {
    key: 'users',
    name: '用户管理',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <UserOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>用户管理</span>
      </div>
    ),
    link: '/app/users',
  },
  {
    key: 'tasks',
    name: '任务',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <UnorderedListOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>任务</span>
      </div>
    ),
    children: [
      {
        link: '/app/tasks',
        title: '阅读任务',
        description: '查看管理所有的任务，任务由系统初始化，开放阶段内不允许修改 任务类型, 外部id 和 开放阶段信息',
        key: 'read-tasks',
      },
      {
        link: '/app/asmTasks',
        title: '组装任务',
        description: '',
        key: 'asm-tasks',
      },
      {
        link: '/app/puzzleTasks',
        title: '拼图任务',
        description: '',
        key: 'puzzle-tasks',
      },
      {
        link: '/app/task-log',
        title: '任务日志',
        description: '查看所有用户的任务开启和完成信息，管理员可以通过 发放奖励 为未完成用户直接发放该任务奖励',
        key: 'task-log',
      },
    ],
  },
  {
    key: 'moments',
    name: '足迹管理',
    title: (
      <div className="flex items-center">
        <span className="anticon">
          <FieldTimeOutlined className="inline-block" style={{ marginRight: '10px' }} />
        </span>
        <span>足迹</span>
      </div>
    ),
    children: [
      {
        link: '/app/moments',
        title: '官方足迹',
        description: '',
        key: 'official-moments',
      },
      {
        link: '/app/user-moments',
        title: '足迹记录',
        description: '',
        key: 'official-moments',
      },
    ],
  },
];

function NavBar(props) {
  const logoImg = '/imgs/chanel-white.png';
  const collapsed = useSelector((state) => state.navBarCollapsed);
  const { location } = props;
  const history = useHistory();
  const handleMenuSelect = (menu) => {
    history.push(menu.key);
  };
  return (
    <Sider trigger={null} width={200} className="site-layout-background" collapsible collapsed={collapsed}>
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]} style={{ height: '100%', borderRight: 0 }} onClick={handleMenuSelect}>
        <a href="/" className={styles.logoContainer}>
          <img className={styles.logo} src={logoImg} alt="logo" />
        </a>
        {menus.map((menu) => {
          if (menu.children && menu.children.length > 0) {
            return (
              <SubMenu key={menu.key} title={menu.title}>
                {menu.children.map((item) => (
                  <Menu.Item key={item.link}>{item.title}</Menu.Item>
                ))}
              </SubMenu>
            );
          } else {
            return <Menu.Item key={menu.link}>{menu.title}</Menu.Item>;
          }
        })}
      </Menu>
    </Sider>
  );
}

NavBar.propTypes = {
  location: PropType.shape({
    pathname: PropType.string.isRequired,
  }).isRequired,
};

export default withRouter(NavBar);
