import React, { useEffect, useState, useReducer, useRef } from 'react';
import { Button, Table, Modal, Form } from 'antd';
import dayjs from 'dayjs';
import isBewteen from 'dayjs/plugin/isBetween';
import { getPuzzleTasks, createPuzzleTask, updatePuzzleTask } from '../../api';
import OSSUploader from '../../components/img-uploader';

dayjs.extend(isBewteen);

export default function() {
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [taskModel, setTaskModel] = useReducer((state, val) => val, {});
  const taskModelRef = useRef();
  taskModelRef.current = taskModel;
  const handleEditQuestion = (row) => {
    setTaskModel(row);
    taskModelRef.current = row;
    form.setFieldsValue(row);
    setModalVisible(true);
  };
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '正确图片',
      render(_, data) {
        return <img src={data.correctImage} style={{ width: '100px' }} alt="正确图片" />;
      },
    },
    {
      title: '错误图片',
      render(_, data) {
        return <img src={data.wrongImage} style={{ width: '100px' }} alt="错误图片" />;
      },
    },
    {
      title: '操作',
      render: (_, row) => {
        return (
          <>
            <Button type="link" onClick={() => handleEditQuestion(row)}>
              编辑
            </Button>
          </>
        );
      },
    },
  ];

  const load = async () => {
    const { data, total } = await getPuzzleTasks({
      take: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
    });
    setDataSource(data);
    setTotal(total);
  };
  const handleTableChange = (params) => {
    setPagination(params);
  };
  const handleCreate = () => {
    const m = {
      correctImage: '',
      wrongImage: '',
      fakeModelImages: [],
    };
    setTaskModel(m);
    taskModelRef.current = m;
    form.setFieldsValue(m);
    setModalVisible(true);
  };
  const handleSubmitTask = () => {
    return form.validateFields().then(async (values) => {
      if (taskModel.id) {
        await updatePuzzleTask({
          ...values,
          id: taskModel.id,
          fakeModelImages: values.fakeModelImages,
        });
      } else {
        await createPuzzleTask({
          ...values,
          fakeModelImages: values.fakeModelImages,
        });
      }
      setModalVisible(false);
      load();
    });
  };
  const handleModalOk = async () => {
    await handleSubmitTask();
    setModalVisible(false);
    load();
  };
  const handleCancelModal = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    load();
  }, [pagination]);
  return (
    <>
      <div>
        <Button onClick={handleCreate} type="primary" style={{ marginBottom: 16 }}>
          新建
        </Button>
        <Table dataSource={dataSource} columns={columns} rowKey="externalId" pagination={{ ...pagination, total }} onChange={handleTableChange} />
      </div>
      <Modal title="任务编辑" visible={modalVisible} destroyOnClose={true} onCancel={handleCancelModal} onOk={handleModalOk} okText="提交" cancelText="取消">
        <Form initialValues={taskModel.current} form={form} layout="vertical">
          <Form.Item name="correctImage" label="正确图片">
            <OSSUploader needCrop={true} />
          </Form.Item>
          <Form.Item name="wrongImage" label="错误图片">
            <OSSUploader needCrop={true} />
          </Form.Item>
          <Form.Item name="fakeModelImages" label="序列帧（可拖拽多个上传）">
            <OSSUploader isMulti={true} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
