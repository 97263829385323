import React, { useEffect, useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table, Modal, Form, Input, Row, Col } from 'antd';
import dayjs from 'dayjs';
import isBewteen from 'dayjs/plugin/isBetween';
import { getReadTasks, updateTask } from '../../api';
import { useHistory } from 'react-router-dom';
import OSSUplaoder from '../../components/img-uploader';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

dayjs.extend(isBewteen);

export default function() {
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [taskModel, setTaskModel] = useReducer((state, val) => val, {
    description: [],
  });
  const ranges = useSelector((state) => state.ranges);
  const handleEditTask = (row) => {
    setTaskModel(row);
    form.setFieldsValue(row);
    setModalVisible(true);
  };
  const handleEditQuestion = (row) => {
    history.push(`/app/${row.externalId}/questions`);
  };
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const handleAddDescription = () => {
    const { description } = taskModel;
    setTaskModel({
      ...taskModel,
      description: [...description, {}],
    });
  };
  const handleDescriptionItemChange = (url, index) => {
    const { description } = taskModel;
    setTaskModel({
      ...taskModel,
      description: description.map((item, idx) => {
        return idx === index
          ? {
              type: /mp4/.test(url) ? 'VIDEO' : 'IMAGE',
              url,
            }
          : item;
      }),
    });
  };
  const handleRemoveDescription = (index) => {
    const { description } = taskModel;
    setTaskModel({
      ...taskModel,
      description: description.filter((item, idx) => idx !== index),
    });
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '外部 ID',
      dataIndex: 'externalId',
    },
    {
      title: '题目数量',
      render(_, data) {
        return data.questions.length;
      },
    },
    {
      title: '更新日期',
      render(_, data) {
        return dayjs(data.updateAt).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '操作',
      render: (_, row) => {
        return (
          <>
            <Button type="link" onClick={() => handleEditTask(row)}>
              编辑题目
            </Button>
            <Button type="link" onClick={() => handleEditQuestion(row)}>
              编辑问题
            </Button>
          </>
        );
      },
    },
  ];

  const load = async () => {
    const { data, total } = await getReadTasks({
      take: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
    });
    setDataSource(data);
    setTotal(total);
  };
  const handleTableChange = (params) => {
    setPagination(params);
  };
  const handleSubmitTask = () => {
    return form.validateFields().then(async (values) => {
      const data = {
        id: taskModel.id,
        name: values.name,
        externalId: values.externalId,
        description: taskModel.description,
      };
      return updateTask(data);
    });
  };
  const handleModalOk = async () => {
    await handleSubmitTask();
    setModalVisible(false);
    load();
  };
  const handleCancelModal = () => {
    setModalVisible(false);
  };
  const isRangeActive = (range) => {
    if (!ranges[range]) return false;
    const { start, end } = ranges[range];
    return dayjs().isBetween(dayjs(start), dayjs(end));
  };
  useEffect(() => {
    load();
  }, [pagination]);
  return (
    <>
      <Table dataSource={dataSource} columns={columns} rowKey="externalId" pagination={{ ...pagination, total }} onChange={handleTableChange} />
      <Modal title="任务编辑" visible={modalVisible} forceRender={true} onCancel={handleCancelModal} onOk={handleModalOk} okText="提交" cancelText="取消">
        <Form initialValues={taskModel} form={form} layout="vertical">
          <Form.Item
            name="name"
            label="任务名称"
            rules={[
              {
                required: true,
                message: '请输入任务名称',
              },
            ]}
          >
            <Input placeholder="请输入任务名称" />
          </Form.Item>

          <Form.Item name="externalId" label="外部 ID">
            <Input placeholder="请输入外部 ID" disabled={isRangeActive(taskModel.range)} />
          </Form.Item>

          <Form.Item label="描述">
            {taskModel.description.map((item, index) => {
              return (
                <Row>
                  <Col span={21}>
                    <OSSUplaoder value={item.url} onChange={(url) => handleDescriptionItemChange(url, index)} />
                  </Col>
                  <Col span={3}>
                    <Button type="danger" onClick={() => handleRemoveDescription(index)}>
                      <DeleteOutlined />
                    </Button>
                  </Col>
                </Row>
              );
            })}
            <Button type="dashed" onClick={handleAddDescription}>
              <PlusOutlined /> 添加
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
