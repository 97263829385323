import React, { useEffect, useState, useReducer } from 'react';
import { Table, Tabs } from 'antd';
import { getTaskLogs, getPuzzleTaskLogs, getBuildUpLogs } from '../../api';

const { TabPane } = Tabs;

export default function() {
  const [dataSource, setDataSource] = useState([]);
  const [puzzleData, setPuzzleData] = useState([]);
  const [buildUpData, setBuildUpData] = useState([]);
  const [total, setTotal] = useState(0);

  const load = async (type = 'question', reset = false) => {
    switch (type) {
      case 'question': {
        const { data, count } = await getTaskLogs({
          skip: reset ? 0 : (pagination.current - 1) * pagination.pageSize,
          take: pagination.pageSize,
        });
        setDataSource(data);
        setTotal(count);
        break;
      }
      case 'puzzle': {
        const { data } = await getPuzzleTaskLogs({
          skip: reset ? 0 : (pagination.current - 1) * pagination.pageSize,
          take: pagination.pageSize,
        });
        setPuzzleData(data);
        break;
      }
      case 'buildup': {
        const { data } = await getBuildUpLogs({
          skip: reset ? 0 : (pagination.current - 1) * pagination.pageSize,
          take: pagination.pageSize,
        });
        setBuildUpData(data);
        break;
      }
      default:
      // NOTHING
    }
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '头像',
      render(_, row) {
        return <img src={row.user.avatar} style={{ width: '50px' }} alt="avatar" />;
      },
    },
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
    },
    {
      title: '店铺',
      dataIndex: ['user', 'chanelShopName'],
    },
    {
      title: '职位',
      dataIndex: ['user', 'position'],
    },
    {
      title: '任务ID',
      dataIndex: 'quizId',
    },
    {
      title: '状态',
      render(_, data) {
        return data.completedAt ? '已完成' : '未完成';
      },
    },
    {
      title: '用时(s)',
      dataIndex: 'seconds',
    },
  ];
  const puzzleColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '头像',
      render(_, row) {
        return <img src={row.user.avatar} style={{ width: '50px' }} alt="avatar" />;
      },
    },
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
    },
    {
      title: '店铺',
      dataIndex: ['user', 'chanelShopName'],
    },
    {
      title: '职位',
      dataIndex: ['user', 'position'],
    },
    {
      title: '任务ID',
      dataIndex: 'puzzleId',
    },
    {
      title: '状态',
      render(_, data) {
        return data.completedAt ? '已完成' : '未完成';
      },
    },
    {
      title: '用时(s)',
      dataIndex: 'seconds',
    },
  ];
  const buildUpColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '头像',
      render(_, row) {
        return <img src={row.user.avatar} style={{ width: '50px' }} alt="avatar" />;
      },
    },
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
    },
    {
      title: '店铺',
      dataIndex: ['user', 'chanelShopName'],
    },
    {
      title: '职位',
      dataIndex: ['user', 'position'],
    },
    {
      title: '任务ID',
      dataIndex: 'buildupId',
    },
    {
      title: '状态',
      render(_, data) {
        return data.completedAt ? '已完成' : '未完成';
      },
    },
    {
      title: '用时(s)',
      dataIndex: 'seconds',
    },
  ];
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const handlePaginationChange = (params) => {
    setPagination(params);
  };
  const handleTabsChange = (key) => {
    load(key, true);
  };

  useEffect(() => {
    load();
  }, [pagination]);
  return (
    <Tabs defaultActiveKey="1" onChange={handleTabsChange}>
      <TabPane tab="答题日志" key="question">
        <Table rowKey="id" columns={columns} dataSource={dataSource} pagination={{ ...pagination, total }} onChange={handlePaginationChange} />
      </TabPane>
      <TabPane tab="拼图任务日志" key="puzzle">
        <Table rowKey="id" columns={puzzleColumns} dataSource={puzzleData} />
      </TabPane>
      <TabPane tab="组装任务日志" key="buildup">
        <Table rowKey="id" columns={buildUpColumns} dataSource={buildUpData} />
      </TabPane>
    </Tabs>
  );
}
