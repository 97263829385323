import React from 'react';
import { Table, Button, Modal, Form, Input, DatePicker } from 'antd';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import OSSUploader from '../../components/img-uploader';
import { getMoments, editMoment } from '../../api';

dayjs.extend(utc);

export default class Moments extends React.Component {
  form = React.createRef();
  state = {
    tableData: [],
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: '年份',
        dataIndex: 'year',
      },
      {
        title: '封面图',
        render(_, data) {
          return <img src={data.cover} alt="cover" style={{ width: '100px' }} />;
        },
      },
      {
        title: '标题',
        dataIndex: 'title',
      },
      {
        title: '简介',
        dataIndex: 'summary',
      },
      {
        title: '更新日期',
        render(_, data) {
          return dayjs(data.updatedAt).format('YYYY-MM-DD');
        },
      },
      {
        title: '操作',
        render: (_, row) => {
          return (
            <>
              <Button type="link" onClick={() => this.handleEdit(row)}>
                编辑
              </Button>
            </>
          );
        },
      },
    ],
    pagination: {
      pageSize: 10,
      current: 1,
    },
    total: 0,
    modalVisible: false,
    dataModel: {},
  };

  load = async () => {
    let { data, total } = await getMoments({
      take: this.state.pagination.pageSize,
      skip: (this.state.pagination.current - 1) * this.state.pagination.pageSize,
    });
    data = data.map((item) => ({
      ...item,
      enabledAt: dayjs(item.enabledAt),
      world: item.descriptions.filter((item) => item.section.toLowerCase() === 'world').map((item) => item.url),
      china: item.descriptions.filter((item) => item.section.toLowerCase() === 'china').map((item) => item.url),
      j12: item.descriptions.filter((item) => item.section.toLowerCase() === 'j12').map((item) => item.url),
      media: item.media.map((media) => media.url),
    }));
    this.setState({
      tableData: data,
      total,
    });
  };

  handleTableChange = (params) => {
    this.setState(
      {
        pagination: params,
      },
      this.load
    );
  };

  handleEdit = (row) => {
    this.form.current.setFieldsValue(row);
    this.setState({
      modalVisible: true,
      dataModel: row,
    });
  };

  handleCancelModal = () => {
    this.setState({
      modalVisible: false,
      dataModel: {},
    });
  };

  handleSubmitMoment = () => {
    function buildDescriptions(values) {
      const map = {
        world: 'World',
        china: 'China',
        j12: 'J12',
      };
      const keys = ['world', 'china', 'j12'];
      let res = [];
      keys.forEach((key) => {
        res = [
          ...res,
          ...values[key].map((item) => ({
            section: map[key],
            url: item,
            type: /.mp4/.test(item) ? 'VIDEO' : 'IMAGE',
          })),
        ];
      });
      return res;
    }
    return this.form.current.validateFields().then(async (values) => {
      const { dataModel } = this.state;
      const data = {
        id: dataModel.id,
        year: dataModel.year,
        cover: values.cover,
        title: values.title,
        summary: values.summary,
        sectionTitles: dataModel.sectionTitles,
        descriptions: buildDescriptions(values),
        quizId: values.quizId ? Number(values.quizId) : null,
        buildupId: values.buildupId ? Number(values.buildupId) : null,
        puzzleId: values.puzzleId ? Number(values.puzzleId) : null,
        momentTitle: values.momentTitle,
        avatar: values.avatar,
        content: values.content,
        media: values.media.map((item) => ({
          url: item,
          type: /.mp4/.test(item) ? 'VIDEO' : 'IMAGE',
        })),
        enabledAt: values.enabledAt.utc().format(),
        myContent: values.myContent,
        myMomentTitle: values.myMomentTitle,
        myCoverImage: values.myCoverImage,
      };
      await editMoment(data);
      this.setState({
        dataModel: {},
        modalVisible: false,
      });
      this.load();
    });
  };

  handleModalOk = () => {
    this.handleSubmitMoment();
  };

  handleSectionTitleChange = (e, index) => {
    const val = e.target.value;
    this.setState({
      dataModel: {
        ...this.state.dataModel,
        sectionTitles: this.state.dataModel.sectionTitles.map((item, idx) => (idx === index ? val : item)),
      },
    });
  };

  handleAddSectionTitle = () => {
    this.setState({
      dataModel: {
        ...this.state.dataModel,
        sectionTitles: [...this.state.dataModel.sectionTitles, ''],
      },
    });
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const { tableData, columns, pagination, total, modalVisible, dataModel } = this.state;
    return (
      <div>
        <Table dataSource={tableData} columns={columns} rowKey="id" pagination={{ ...pagination, total }} onChange={this.handleTableChange} />
        <Modal
          width="80%"
          title="足迹编辑"
          visible={modalVisible}
          forceRender={true}
          onCancel={this.handleCancelModal}
          onOk={this.handleModalOk}
          okText="提交"
          cancelText="取消"
        >
          <Form name="form" initialValues={dataModel} ref={this.form} layout="vertical">
            <Form.Item name="title" label="标题">
              <Input />
            </Form.Item>
            <Form.Item name="summary" label="描述">
              <Input />
            </Form.Item>
            <Form.Item name="myMomentTitle" label="「我的」页标题">
              <Input />
            </Form.Item>
            <Form.Item name="myCoverImage" label="「我的」页图片">
              {modalVisible && <OSSUploader isMulti={false} />}
            </Form.Item>
            <Form.Item name="avatar" label="消息头像">
              {modalVisible && <OSSUploader needCrop={true} isMulti={false} />}
            </Form.Item>
            <Form.Item name="cover" label="消息图片">
              {modalVisible && <OSSUploader needCrop={true} aspect={1.78} />}
            </Form.Item>
            <Form.Item label="消息框内的三句话">
              {dataModel.sectionTitles &&
                dataModel.sectionTitles.map((title, index) => <Input defaultValue={title} onChange={(e) => this.handleSectionTitleChange(e, index)} />)}
              {dataModel.sectionTitles < 3 && (
                <Button type="dashed" onClick={this.handleAddSectionTitle}>
                  <PlusOutlined /> 添加选项
                </Button>
              )}
            </Form.Item>
            <Form.Item name="momentTitle" label="标题">
              <Input />
            </Form.Item>
            <Form.Item name="content" label="描述">
              <Input />
            </Form.Item>
            <Form.Item name="media" label="消息视频/图片">
              {dataModel.media && <OSSUploader isMulti={true} />}
            </Form.Item>
            <Form.Item name="quizId" label="问答挑战 ID">
              <Input readOnly />
            </Form.Item>
            <Form.Item name="buildupId" label="组装游戏 ID">
              <Input />
            </Form.Item>
            <Form.Item name="puzzleId" label="拼图游戏 ID">
              <Input />
            </Form.Item>
            <Form.Item name="enabledAt" label="开启时间">
              <DatePicker showTime />
            </Form.Item>
            <Form.Item name="world" label="世界">
              {modalVisible && <OSSUploader isMulti />}
            </Form.Item>
            <Form.Item name="china" label="中国">
              {modalVisible && <OSSUploader isMulti />}
            </Form.Item>
            <Form.Item name="j12" label="J12">
              {modalVisible && <OSSUploader isMulti />}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
