import axios from 'axios';
import { gql } from 'apollo-boost';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { notification } from 'antd';

const env = process.env.REACT_APP_ENV || 'dev';

const gqlHost = {
  dev: 'https://j12-service.dev.dozto.com/graphql',
  stag: 'https://j12-service-stag.chanel-digital.com/graphql',
  prod: 'https://j12-service.chanel-digital.com/graphql',
};

const cache = new InMemoryCache({
  addTypename: false,
});
const httpLink = new HttpLink({
  uri: gqlHost[env],
  credentials: 'include',
});
const doAuthLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors[0].status === 401) {
    window.location.href = '/login';
    return;
  }
  if (graphQLErrors[0].message) {
    notification.error({
      message: graphQLErrors[0].message,
    });
  }
});
const client = new ApolloClient({
  link: doAuthLink.concat(httpLink),
  cache,
});

export const login = async (data) => {
  const res = await client.query({
    query: gql`
      query login($data: AdminLoginInput!) {
        adminLogin(data: $data) {
          userId
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const getTask = (id) => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getQuiz($id: Int!) {
          getQuiz(id: $id) {
            id
            name
            externalId
            description {
              url
              type
              thumbnail
              videoSnapshot
              videoSnapshotThumbnail
            }
            questions {
              id
              externalId
              title
              type
              description {
                url
                type
              }
              options {
                index
                content
                contentType
                url
              }
              answer
              ignoreAnswerOrder
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        id,
      },
    })
    .then(({ data }) => {
      return data.getQuiz;
    });
};

export const updateTask = async (data) => {
  const res = await client.mutate({
    mutation: gql`
      mutation demo($data: UpdateQuizInput!) {
        updateQuiz(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const getReadTasks = (pagination) => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getReadTasks($pagination: PaginationInput!) {
          getQuizzes(pagination: $pagination) {
            id
            name
            externalId
            createdAt
            updatedAt
            description {
              url
              type
              thumbnail
              videoSnapshot
              videoSnapshotThumbnail
            }
            questions {
              id
              externalId
              title
              type
              options {
                index
                content
                contentType
              }
              answer
              ignoreAnswerOrder
              createdAt
              updatedAt
            }
          }
          countQuizzes {
            count
          }
        }
      `,
      variables: {
        pagination: {
          ...pagination,
          orderBy: 'id',
          orderByMode: 'ASC',
        },
      },
    })
    .then(({ data }) => {
      return {
        data: data.getQuizzes,
        total: data.countQuizzes.count,
      };
    });
};

export const getOSSPolicy = async () => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query OSSPolicy {
        getAliCloudOssUploadPolicy {
          accessid
          host
          expire
          signature
          policy
          dir
          callback
        }
      }
    `,
  });
  return data.getAliCloudOssUploadPolicy;
};

export const createQuestion = async (data) => {
  const res = await client.mutate({
    mutation: gql`
      mutation demo($data: CreateQuestionInput!) {
        createQuestion(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const updateQuestion = async (data) => {
  const res = await client.mutate({
    mutation: gql`
      mutation demo($data: UpdateQuestionInput!) {
        updateQuestion(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const getPuzzleTasks = async (pagination) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query demo($pagination: PaginationInput!) {
        getPuzzles(pagination: $pagination) {
          id
          correctImage
          wrongImage
          updatedAt
          fakeModelImages
        }
        countPuzzles {
          count
        }
      }
    `,
    variables: {
      pagination,
    },
  });
  return {
    data: data.getPuzzles,
    total: data.countPuzzles.count,
  };
};

export const createPuzzleTask = async (data) => {
  const res = await client.mutate({
    mutation: gql`
      mutation demo($data: CreatePuzzleInput!) {
        createPuzzle(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const updatePuzzleTask = async (data) => {
  const res = await client.mutate({
    mutation: gql`
      mutation demo($data: UpdatePuzzleInput!) {
        updatePuzzle(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const getAsmTasks = async (pagination) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query demo($pagination: PaginationInput!) {
        getBuildups(pagination: $pagination) {
          id
          name
          background
          candidates {
            index
            content
            contentType
            url
          }
          coordinate {
            x
            y
          }
          answer
          fakeModelImages
        }
        countBuildups {
          count
        }
      }
    `,
    variables: {
      pagination,
    },
  });
  return {
    data: data.getBuildups,
    total: data.countBuildups.count,
  };
};

export const createAsmTask = async (data) => {
  const res = await client.mutate({
    mutation: gql`
      mutation demo($data: CreateBuildupInput!) {
        createBuildup(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const editAsmTask = async (data) => {
  const res = await client.mutate({
    mutation: gql`
      mutation demo($data: UpdateBuildupInput!) {
        updateBuildup(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const getTaskLogs = (pagination) => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getUserQuizzes($pagination: PaginationInput!) {
          getUserQuizzes(pagination: $pagination) {
            id
            userId
            quizId
            quiz {
              name
            }
            answer {
              questionId
              answer
            }
            totalPoints
            seconds
            user {
              id
              name
              avatar
              email
              gender
              mobile
              alias
              position
              chanelShopName
            }
            completedAt
          }
          countUserQuizzes {
            count
          }
        }
      `,
      variables: {
        pagination,
      },
    })
    .then(({ data }) => {
      return {
        data: data.getUserQuizzes,
        count: data.countUserQuizzes.count,
      };
    });
};

export const getPuzzleTaskLogs = (pagination) => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getUserPuzzles {
          getUserPuzzles {
            id
            puzzleId
            seconds
            user {
              id
              name
              avatar
              email
              gender
              mobile
              alias
              position
              chanelShopName
            }
            completedAt
          }
        }
      `,
      variables: {
        pagination,
      },
    })
    .then(({ data }) => {
      return {
        data: data.getUserPuzzles,
      };
    });
};

export const getBuildUpLogs = (pagination) => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getUserBuildups {
          getUserBuildups {
            id
            buildupId
            seconds
            user {
              id
              name
              avatar
              email
              gender
              mobile
              alias
              position
              chanelShopName
            }
            completedAt
          }
        }
      `,
    })
    .then(({ data }) => {
      return {
        data: data.getUserBuildups,
      };
    });
};

export const getMoments = (pagination) => {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getOfficialMoments($pagination: PaginationInput!) {
          getOfficialMoments(pagination: $pagination) {
            id
            year
            cover
            title
            summary
            sectionTitles
            myMomentTitle
            myContent
            myCoverImage
            descriptions {
              type
              url
              section
              content
              thumbnail
            }
            quizId
            buildupId
            puzzleId
            momentTitle
            avatar
            content
            media {
              type
              url
            }
            enabledAt
          }
          countOfficialMoments {
            count
          }
        }
      `,
      variables: {
        pagination,
      },
    })
    .then(({ data }) => {
      return {
        data: data.getOfficialMoments,
        total: data.countOfficialMoments.count,
      };
    });
};

export const editMoment = async (data) => {
  const res = await client.mutate({
    mutation: gql`
      mutation updateOfficialMoment($data: UpdateOfficialMomentInput!) {
        updateOfficialMoment(data: $data) {
          success
        }
      }
    `,
    variables: {
      data,
    },
  });
  return res;
};

export const getUserMoments = async (pagination) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query getUserMoments($pagination: PaginationInput!) {
        getUserMoments(pagination: $pagination) {
          id
          year
          userId
          user {
            name
            avatar
            alias
            position
            chanelShopName
          }
          likes {
            id
          }
          comments {
            content
            id
            userId
            userMomentId
            user {
              name
              avatar
              alias
              position
              chanelShopName
            }
          }
          content
          media {
            type
            url
          }
          createdAt
          instantCommentsCount
        }
        countUserMoments {
          count
        }
      }
    `,
    variables: {
      pagination,
    },
  });
  return {
    data: data.getUserMoments,
    total: data.countUserMoments.count,
  };
};

export const removeQuestion = async (params) => {
  const res = await client.mutate({
    mutation: gql`
      mutation removeQuestion($quizId: Int!, $questionId: Int!) {
        removeQuestion(quizId: $quizId, questionId: $questionId) {
          success
        }
      }
    `,
    variables: {
      ...params,
    },
  });
  return res;
};

export const removeUserMoment = async (id) => {
  const res = await client.mutate({
    mutation: gql`
      mutation removeUseMoment($userMomentId: Float!) {
        removeUseMoment(userMomentId: $userMomentId) {
          success
        }
      }
    `,
    variables: {
      userMomentId: id,
    },
  });
  return res;
};

export const removeUserComment = async (id) => {
  const res = await client.mutate({
    mutation: gql`
      mutation removeUserMomentComment($id: Float!) {
        removeUserMomentComment(id: $id) {
          success
        }
      }
    `,
    variables: {
      id,
    },
  });
  return res;
};

export const getUsers = async ({ pagination, year, keyword }) => {
  const { data } = await client.query({
    query: gql`
      query getPointRank($pagination: PaginationInput!, $year: String, $keyword: String) {
        getPointRank(pagination: $pagination, year: $year, keyword: $keyword) {
          id
          year
          userId
          user {
            name
            avatar
            email
            gender
            mobile
            alias
            position
            chanelShopName
          }
          points
          position
        }
        getPointRankItemCount(year: $year) {
          count
        }
      }
    `,
    variables: {
      pagination,
      year,
      keyword,
    },
  });
  return {
    data: data.getPointRank,
    total: data.getPointRankItemCount.count,
  };
};
