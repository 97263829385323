import React from 'react';
import 'antd/dist/antd.css';
import { Layout } from 'antd';
import { Provider } from 'react-redux';
import { Switch, Redirect, Route, BrowserRouter as Router } from 'react-router-dom';
import Login from './views/login';
import Tasks from './views/tasks';
import Questions from './views/questions';
import TaskLog from './views/tasks/log';
import PuzzleTasks from './views/tasks/puzzles';
import AsmTasks from './views/tasks/asms';
import Moments from './views/moments';
import UserMoments from './views/moments/user';
import styles from './App.module.css';
import Header from './components/header';
import NavBar from './components/navbar';
import Users from './views/users';
import store from './store';
import Breadcrumb from './components/breadcrumb';

const { Content } = Layout;

function AppLayout() {
  return (
    <Layout className={styles.container}>
      <NavBar />
      <Layout className={styles.body}>
        <Header />
        <Layout style={{ padding: '24px' }}>
          <Content className="site-layout-background">
            <Breadcrumb />
            <Switch>
              <Redirect exact from="/app" to="/app/tasks" />
              <Route path="/app/tasks">
                <Tasks />
              </Route>
              <Route path="/app/puzzleTasks">
                <PuzzleTasks />
              </Route>
              <Route path="/app/asmTasks">
                <AsmTasks />
              </Route>
              <Route path="/app/task-log">
                <TaskLog />
              </Route>
              <Route path="/app/:taskId/questions">
                <Questions />
              </Route>
              <Route path="/app/moments">
                <Moments />
              </Route>
              <Route path="/app/user-moments">
                <UserMoments />
              </Route>
              <Route path="/app/users">
                <Users />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Redirect exact from="/" to="/app/users" />
          <Route path="/app">
            <AppLayout />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
