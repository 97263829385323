import React, { useEffect, useState, useReducer, useRef } from 'react';
import { Button, Table, Modal, Form, Row, Col, Input, Popconfirm, Switch } from 'antd';
import dayjs from 'dayjs';
import isBewteen from 'dayjs/plugin/isBetween';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getAsmTasks, createAsmTask, editAsmTask } from '../../api';
import OSSUploader from '../../components/img-uploader';

dayjs.extend(isBewteen);

function AsmOption({ option, onChange, onRemove }) {
  const handleIndexChange = (e) => {
    onChange({
      ...option,
      index: e.target.value,
    });
  };
  const handleContentChange = (e) => {
    onChange({
      ...option,
      content: e.target.value,
    });
  };
  const handleImageChange = (url) => {
    onChange({
      ...option,
      url,
      contentType: 'IMAGE',
    });
  };
  const handleRemove = () => {
    onRemove();
  };
  const handleCheckCorrect = (value) => {
    onChange({
      ...option,
      isCorrect: value,
    });
  };
  return (
    <Row>
      <Col span={4}>
        <Input onChange={handleIndexChange} placeholder="唯一选项，如「A」" defaultValue={option.index} />
      </Col>
      <Col span={6}>
        <Input onChange={handleContentChange} placeholder="选项描述" defaultValue={option.content} />
      </Col>
      <Col span={6}>
        <OSSUploader onChange={handleImageChange} isMulti={false} value={option.url} placeholder="选项描述" />
      </Col>
      <Col span={4}>
        <Switch checkedChildren="正确" unCheckedChildren="错误" checked={option.isCorrect} onChange={handleCheckCorrect} />
      </Col>
      <Col span={4}>
        <Popconfirm title="确认删除？" onConfirm={handleRemove} okText="是" cancelText="否">
          <Button type="danger">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
}

export default function() {
  const [dataSource, setDataSource] = useState([]);
  const taskModelRef = useRef();
  const [pagination, setPagination] = useReducer(
    function(state, val) {
      return val;
    },
    {
      pageSize: 10,
      current: 1,
    }
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [taskModel, setTaskModel] = useReducer((state, val) => val, {
    candidates: [],
  });
  taskModelRef.current = taskModel;
  const handleEditQuestion = (row) => {
    setTaskModel(row);
    taskModelRef.current = row;
    form.setFieldsValue(row);
    setModalVisible(true);
  };
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '标题',
      dataIndex: 'name',
    },
    {
      title: '图片',
      render(_, data) {
        return <img src={data.background} style={{ width: '100px' }} alt="图片" />;
      },
    },
    {
      title: '操作',
      render: (_, row) => {
        return (
          <>
            <Button type="link" onClick={() => handleEditQuestion(row)}>
              编辑
            </Button>
          </>
        );
      },
    },
  ];

  const load = async () => {
    let { data, total } = await getAsmTasks({
      take: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
    });
    data = data.map((row) => ({
      ...row,
      candidates: row.candidates.map((candidate) => ({
        ...candidate,
        isCorrect: candidate.index === row.answer,
      })),
      x: row.coordinate.x * 100,
      y: row.coordinate.y * 100,
    }));
    setDataSource(data);
    setTotal(total);
  };
  const handleAddOption = () => {
    setTaskModel({
      ...taskModel,
      candidates: [...taskModel.candidates, { index: '', content: '', url: '', contentType: 'IMAGE', isCorrect: false }],
    });
    taskModelRef.current = taskModel;
  };
  const handleRemoveOption = (index) => {
    setTaskModel({
      ...taskModel,
      candidates: taskModel.candidates.filter((item, idx) => idx !== index),
    });
    taskModelRef.current = taskModel;
  };
  const handleOptionChange = (index, option) => {
    setTaskModel({
      ...taskModel,
      candidates: taskModel.candidates.map((item, idx) => {
        if (option.isCorrect) item.isCorrect = false;
        return index === idx ? option : item;
      }),
    });
    taskModelRef.current = taskModel;
  };
  const handleTableChange = (params) => {
    setPagination(params);
  };
  const handleCreate = () => {
    const m = {
      name: '',
      background: '',
      answer: '',
      candidates: [],
      fakeModelImages: [],
      x: '50',
      y: '50',
    };
    setTaskModel(m);
    taskModelRef.current = m;
    form.setFieldsValue(m);
    setModalVisible(true);
  };
  const handleSubmitTask = () => {
    return form.validateFields().then(async (values) => {
      if (taskModel.id) {
        await editAsmTask({
          ...values,
          id: taskModel.id,
          answer: taskModel.candidates.find((item) => item.isCorrect).index,
          coordinate: {
            x: Number(values.x) / 100,
            y: Number(values.y) / 100,
          },
          candidates: taskModel.candidates.map(({ index, content, contentType, url }) => ({
            index,
            content,
            contentType,
            url,
          })),
          x: undefined,
          y: undefined,
        });
      } else {
        await createAsmTask({
          ...values,
          answer: taskModel.candidates.find((item) => item.isCorrect).index,
          coordinate: {
            x: Number(values.x) / 100,
            y: Number(values.y) / 100,
          },
          candidates: taskModel.candidates.map(({ index, content, contentType, url }) => ({
            index,
            content,
            contentType,
            url,
          })),
          x: undefined,
          y: undefined,
        });
      }
      setModalVisible(false);
      load();
    });
  };
  const handleModalOk = async () => {
    await handleSubmitTask();
    setModalVisible(false);
    load();
  };
  const handleCancelModal = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    if (modalVisible) form.setFieldsValue(taskModel);
  }, [modalVisible]);
  useEffect(() => {
    load();
  }, [pagination]);
  return (
    <>
      <div>
        <Button onClick={handleCreate} type="primary" style={{ marginBottom: 16 }}>
          新建
        </Button>
        <Table dataSource={dataSource} columns={columns} rowKey="externalId" pagination={{ ...pagination, total }} onChange={handleTableChange} />
      </div>
      <Modal
        width="80%"
        title="任务编辑"
        visible={modalVisible}
        destroyOnClose={true}
        onCancel={handleCancelModal}
        onOk={handleModalOk}
        okText="提交"
        cancelText="取消"
      >
        <Form initialValues={taskModelRef.current} form={form} layout="vertical">
          <Form.Item name="name" label="标题">
            <Input />
          </Form.Item>
          <Form.Item name="background" label="题目图片">
            <OSSUploader needCrop={true} />
          </Form.Item>
          <Form.Item name="x" label="背景图左上角至放置位置中心点x轴百分比">
            <Input suffix="%" />
          </Form.Item>
          <Form.Item name="y" label="背景图左上角至放置位置中心点y轴百分比">
            <Input suffix="%" />
          </Form.Item>
          <Form.Item label="选项">
            {taskModel.candidates.map((option, index) => (
              <AsmOption option={option} onRemove={() => handleRemoveOption(index)} onChange={(option) => handleOptionChange(index, option)} />
            ))}
            <Button type="dashed" onClick={handleAddOption}>
              <PlusOutlined /> 添加选项
            </Button>
          </Form.Item>
          <Form.Item name="fakeModelImages" label="序列帧图片（可拖拽多个上传）">
            <OSSUploader isMulti={true} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
