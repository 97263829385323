const CDNMap = {
  dev: 'https://cdn.dev.dozto.com',
  stag: 'https://cdn.staging.chanel-digital.com',
  prod: 'https://coco-cdn.chanel-digital.com',
};
export const CDN_HOST = CDNMap[process.env.REACT_APP_ENV];

export const QUESTION_TYPES = [
  { label: '单选', value: 'SINGLE_CHOICE' },
  { label: '多选', value: 'MULTI_CHOICE' },
  { label: '填空', value: 'FILL_BLANK' },
];
