import uniqBy from 'lodash/uniqBy';
import flattenDeep from 'lodash/flattenDeep';
import find from 'lodash/find';

export const buildDepartment = (item) => {
  const res = {
    id: item.id,
    key: item.id,
    title: item.name,
    name: item.name,
    isDepartment: true,
  };
  if (item.users) {
    res.children = item.users.map((user) => ({
      id: user.id,
      key: item.id + '-' + user.id,
      title: user.name,
      name: user.name,
      isUser: true,
    }));
  }
  return res;
};

export const findTargetInTree = (tree, target) => {
  let current = tree;
  if (current.key === target) return current;
  else if (current.children) {
    for (let i = 0; i < current.children.length; i++) {
      const res = findTargetInTree(current.children[i], target);
      if (res) return res;
    }
  }
  return null;
};

export const departmentArrayToTree = (deps, users) => {
  const uniqUsers = uniqBy(flattenDeep(users), 'id');
  uniqUsers.forEach((user) => {
    user.departmentIds.forEach((id) => {
      const dep = find(deps, (d) => d.id === id);
      if (dep.users) dep.users.push(user);
      else dep.users = [user];
    });
  });
  let root = null;
  for (let i = 0; i < deps.length; i++) {
    const current = buildDepartment(deps[i]);
    if (deps[i].parentId === 0) {
      root = current;
    } else {
      const parent = findTargetInTree(root, deps[i].parentId);
      if (parent.children) parent.children.push(current);
      else parent.children = [current];
    }
  }
  return root;
};

export const buildHTML = ({ title, content }) => {
  const html = document.createElement('html');
  html.innerHTML = `
    <head>
      <title>${title}</title>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
    </head>
    <body>
      ${content}
    </body>
  `;
  return html.outerHTML;
};

export const findRoute = (navs, target, path = []) => {
  for (let i = 0; i < navs.length; i++) {
    if (navs[i].link && navs[i].link === target) return { route: navs[i], path: [...path, navs[i]] };
    else if (navs[i].children) {
      const res = findRoute(navs[i].children, target, [...path, navs[i]]);
      if (res) return res;
    }
  }
  return null;
};
