import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styles from './login.module.css';
import { login } from '../../api';

export default function() {
  const history = useHistory();
  const handleSubmit = async (values) => {
    await login(values);
    history.push('/app');
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-200">
      <Form className="w-3/12" name="basic" onFinish={handleSubmit}>
        <div className="flex justify-center mb-8">
          <img className={styles.loginFormLogo} src="/imgs/chanel-black.png" alt="logo" />
        </div>
        <Form.Item
          name="login"
          rules={[
            {
              required: true,
              message: '请输入用户名',
            },
          ]}
        >
          <Input prefix={<UserOutlined className={styles.siteFormItemIcon} />} placeholder="用户名" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: '请输入密码',
            },
          ]}
        >
          <Input prefix={<LockOutlined className={styles.siteFormItemIcon} />} type="password" placeholder="密码" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.loginFormButton}>
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
