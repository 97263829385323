import React from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import urljoin from 'url-join';
import ImgCrop from 'antd-img-crop';
import { getOSSPolicy } from '../../api';
import { CDN_HOST } from '../../consts';

const UploadBtn = ({ loading }) => {
  return loading ? (
    <LoadingOutlined />
  ) : (
    <Button>
      <UploadOutlined /> 点击上传
    </Button>
  );
};

const PreviewEl = ({ url }) => {
  if (/.mp4/.test(url)) {
    return <video src={url} />;
  } else {
    return <img src={url} alt="preview" />;
  }
};

export default class ImgUpload extends React.Component {
  state = {
    OSSData: {},
    loading: false,
  };

  getFileName = (url) => {
    const arr = url.split('/');
    return arr[arr.length - 1];
  };

  formatFileList = (list) => {
    if (!list) return [];
    if (Array.isArray(list)) {
      return list.map((item, index) => ({
        uid: index,
        name: this.getFileName(item),
        status: 'done',
        url: item,
      }));
    } else {
      return [
        {
          uid: 0,
          name: this.getFileName(list),
          status: 'done',
          url: list,
        },
      ];
    }
  };

  init = () => {
    return new Promise(async (resolve) => {
      const res = await getOSSPolicy();
      this.setState(
        {
          OSSData: res,
        },
        resolve
      );
    });
  };

  handleBeforeUpload = async () => {
    return await this.init();
  };

  transformFile = (file) => {
    // eslint-disable-next-line no-param-reassign
    file.url = urljoin(CDN_HOST, this.state.OSSData.dir, file.name);
    return file;
  };

  handleFileChange = async ({ file, fileList }) => {
    const { props } = this;
    if (file.status === 'done' || file.status === 'removed') {
      this.setState({
        loading: false,
      });
      if (props.isMulti) {
        if (props.useMediaId) props.onChange(fileList);
        else props.onChange(fileList.map((item) => item.url));
      } else {
        const finalFile = props.useMediaId ? file : file.url;
        props.onChange(file.status === 'removed' ? null : finalFile);
      }
    } else if (file.status === 'uploading') {
      this.setState({
        loading: true,
      });
    }
  };

  getExtraData = (file) => ({
    key: this.state.OSSData.dir + file.name,
    OSSAccessKeyId: this.state.OSSData.accessid,
    policy: this.state.OSSData.policy,
    Signature: this.state.OSSData.signature,
  });

  render() {
    const { props } = this;
    const { loading, OSSData } = this.state;
    const imgurl = Array.isArray(props.value) ? null : props.value;
    console.log(props.id, props.value);

    if (props.needCrop) {
      return (
        <ImgCrop aspect={props.aspect || 1}>
          <Upload
            transformFile={this.transformFile}
            action={OSSData.host}
            beforeUpload={this.handleBeforeUpload}
            onChange={this.handleFileChange}
            listType="picture-card"
            defaultFileList={this.formatFileList(props.value)}
            data={this.getExtraData}
            showUploadList={!!props.isMulti}
            multiple={!!props.isMulti}
          >
            {imgurl ? <PreviewEl url={imgurl} /> : <UploadBtn loading={loading} />}
          </Upload>
        </ImgCrop>
      );
    }
    return (
      <Upload
        transformFile={this.transformFile}
        action={OSSData.host}
        beforeUpload={this.handleBeforeUpload}
        onChange={this.handleFileChange}
        listType="picture-card"
        showUploadList={!!props.isMulti}
        defaultFileList={this.formatFileList(props.value)}
        data={this.getExtraData}
        multiple={!!props.isMulti}
      >
        {imgurl ? <PreviewEl url={imgurl} /> : <UploadBtn loading={loading} />}
      </Upload>
    );
  }
}
