import React from 'react';
import { Layout, Menu } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import styles from './header.module.css';
import { setNavBarCollapsed } from '../../store/actions';

const { Header } = Layout;
export default withRouter(function({ history }) {
  const menus = [];
  const collapsed = useSelector((state) => state.navBarCollapsed);
  const dispatch = useDispatch();
  const toggleCollapsed = () => {
    dispatch(setNavBarCollapsed(!collapsed));
  };
  const handleLogout = () => {
    history.replace('/login');
  };
  return (
    <Header className={'header flex items-center justify-between ' + styles.header}>
      <span className={styles.collapsed} onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
      </span>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} style={{ lineHeight: '64px' }}>
        {menus.map((menu) => (
          <Menu.Item key={menu.key}>{menu.title}</Menu.Item>
        ))}
      </Menu>
      <div className="flex items-center content-center">
        管理员
        <span className={styles.logout} onClick={handleLogout}>
          <ExportOutlined />
        </span>
      </div>
    </Header>
  );
});
