import React from 'react';
import { Table, Button, Modal, Form, Input, DatePicker } from 'antd';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import OSSUploader from '../../components/img-uploader';
import { getUsers } from '../../api';

dayjs.extend(utc);

export default class Users extends React.Component {
  form = React.createRef();
  state = {
    tableData: [],
    columns: [
      {
        title: '用户 ID',
        dataIndex: 'id',
      },
      {
        title: '头像',
        render(_, row) {
          return <img src={row.user.avatar} style={{ width: '50px' }} alt="avatar" />;
        },
      },
      {
        title: '姓名',
        dataIndex: ['user', 'name'],
      },
      {
        title: '别名',
        dataIndex: ['user', 'alias'],
      },
      {
        title: '店铺',
        dataIndex: ['user', 'chanelShopName'],
      },
      {
        title: '积分',
        dataIndex: 'points',
      },
      {
        title: '排名',
        dataIndex: 'position',
      },
    ],
    pagination: {
      pageSize: 10,
      current: 1,
    },
    total: 0,
    modalVisible: false,
    dataModel: {},
  };

  load = async (keyword = '') => {
    let { data, total } = await getUsers({
      pagination: {
        take: this.state.pagination.pageSize,
        skip: (this.state.pagination.current - 1) * this.state.pagination.pageSize,
      },
      keyword,
    });
    this.setState({
      tableData: data,
      total,
    });
  };

  handleTableChange = (params) => {
    this.setState(
      {
        pagination: params,
      },
      this.load
    );
  };

  handleEdit = (row) => {
    this.form.current.setFieldsValue(row);
    this.setState({
      modalVisible: true,
      dataModel: row,
    });
  };

  handleCancelModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  handleSubmitMoment = () => {
    function buildDescriptions(values) {
      const keys = ['World', 'China', 'J12'];
      let res = [];
      keys.forEach((key) => {
        res = [
          ...res,
          ...values[key].map((item) => ({
            section: key,
            url: item,
            type: /.mp4/.test(item) ? 'VIDEO' : 'IMAGE',
          })),
        ];
      });
      return res;
    }
    return this.form.current.validateFields().then(async (values) => {
      const { dataModel } = this.state;
      const data = {
        id: dataModel.id,
        year: dataModel.year,
        cover: values.cover,
        title: values.title,
        summary: values.summary,
        sectionTitles: dataModel.sectionTitles,
        descriptions: buildDescriptions(values),
        quizId: values.quizId ? Number(values.quizId) : null,
        buildupId: values.buildupId ? Number(values.buildupId) : null,
        puzzleId: values.puzzleId ? Number(values.puzzleId) : null,
        momentTitle: values.momentTitle,
        avatar: values.avatar,
        content: values.content,
        media: dataModel.media.map((item) => ({
          url: item,
          type: /.mp4/.test(item) ? 'VIDEO' : 'IMAGE',
        })),
        enabledAt: values.enabledAt.utc().format(),
      };
      this.setState({
        dataModel: {},
        modalVisible: false,
      });
      this.load();
    });
  };

  handleModalOk = () => {
    this.handleSubmitMoment();
  };

  handleSectionTitleChange = (e, index) => {
    const val = e.target.value;
    this.setState({
      dataModel: {
        ...this.state.dataModel,
        sectionTitles: this.state.dataModel.sectionTitles.map((item, idx) => (idx === index ? val : item)),
      },
    });
  };

  handleAddSectionTitle = () => {
    this.setState({
      dataModel: {
        ...this.state.dataModel,
        sectionTitles: [...this.state.dataModel.sectionTitles, ''],
      },
    });
  };

  handleMediaChange = (url, index) => {
    this.setState({
      ...this.state.dataModel,
      media: this.state.dataModel.media.map((item, idx) => {
        return index === idx
          ? {
              url,
              type: /.mp4/.test(url) ? 'VIDEO' : 'IMAGE',
            }
          : item;
      }),
    });
  };

  handleSearch = (values) => {
    const { keyword } = values;
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          offset: 0,
        },
      },
      () => {
        this.load(keyword);
      }
    );
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const { tableData, columns, pagination, total, modalVisible, dataModel } = this.state;
    return (
      <div>
        <div className="mb-4">
          <Form name="search" layout="inline" onFinish={this.handleSearch}>
            <Form.Item name="keyword">
              <Input placeholder="根据用户姓名查找" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Table dataSource={tableData} columns={columns} rowKey="id" pagination={{ ...pagination, total }} onChange={this.handleTableChange} />
        <Modal
          width="80%"
          title="足迹编辑"
          visible={modalVisible}
          forceRender={true}
          onCancel={this.handleCancelModal}
          onOk={this.handleModalOk}
          okText="提交"
          cancelText="取消"
        >
          <Form name="form" initialValues={dataModel} ref={this.form} layout="vertical">
            <Form.Item name="title" label="标题">
              <Input />
            </Form.Item>
            <Form.Item name="summary" label="描述">
              <Input />
            </Form.Item>
            <Form.Item name="avatar" label="消息头像">
              {modalVisible && <OSSUploader needCrop={true} isMulti={false} />}
            </Form.Item>
            <Form.Item name="cover" label="消息图片">
              {modalVisible && <OSSUploader needCrop={true} aspect={1.78} />}
            </Form.Item>
            <Form.Item label="消息框内的三句话">
              {dataModel.sectionTitles &&
                dataModel.sectionTitles.map((title, index) => <Input defaultValue={title} onChange={(e) => this.handleSectionTitleChange(e, index)} />)}
              {dataModel.sectionTitles < 3 && (
                <Button type="dashed" onClick={this.handleAddSectionTitle}>
                  <PlusOutlined /> 添加选项
                </Button>
              )}
            </Form.Item>
            <Form.Item name="momentTitle" label="朋友圈内标题">
              <Input />
            </Form.Item>
            <Form.Item name="content" label="朋友圈文字内容">
              <Input />
            </Form.Item>
            <Form.Item label="朋友圈里的视频/图片">
              {dataModel.media && (
                <OSSUploader isMulti={true} value={dataModel.media.map((item) => item.url)} onChange={(url, index) => this.handleMediaChange(url, index)} />
              )}
            </Form.Item>
            <Form.Item name="quizId" label="问答挑战 ID">
              <Input />
            </Form.Item>
            <Form.Item name="buildupId" label="组装游戏 ID">
              <Input />
            </Form.Item>
            <Form.Item name="puzzleId" label="拼图游戏 ID">
              <Input />
            </Form.Item>
            <Form.Item name="enabledAt" label="开启时间">
              <DatePicker showTime />
            </Form.Item>
            <Form.Item name="World" label="世界">
              {dataModel.World && <OSSUploader isMulti />}
            </Form.Item>
            <Form.Item name="China" label="中国">
              {dataModel.China && <OSSUploader isMulti />}
            </Form.Item>
            <Form.Item name="J12" label="J12">
              {dataModel.J12 && <OSSUploader isMulti />}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
