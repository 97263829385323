import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card } from 'antd';
import { withRouter } from 'react-router';
import { menus } from '../navbar';
import { findRoute } from '../../utils';
import styles from './breadcrumb.module.css';

function BreadCrumb(props) {
  const { location } = props;
  const [path, setPath] = useState([]);
  useEffect(() => {
    const route = findRoute(menus, location.pathname);
    if (route) {
      setPath(route.path);
    }
  }, [location]);
  return (
    <Card style={{ marginBottom: '20px' }}>
      <Breadcrumb>
        <Breadcrumb.Item href="/app">首页</Breadcrumb.Item>
        {path.map((item) => {
          return item.link ? (
            <Breadcrumb.Item key={item.key} link={item.link}>
              {item.name || item.title}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={item.key}>{item.name}</Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <div className={styles.pageTitle}>
        <h2>{path.length > 0 ? path[path.length - 1].name || path[path.length - 1].title : ''}</h2>
        <p>{path.length > 0 ? path[path.length - 1].description : ''}</p>
      </div>
    </Card>
  );
}

export default withRouter(BreadCrumb);
